<template>
  <div>
    <h4 class="mb-4"><strong>Paga tu plan</strong></h4>

    <taiff-card :name="data.payment.tariff.name" :include="data.payment.tariff.include"
                :price="data.payment.tariff.price"/>

    <div v-if="data.payment.loan > 0" class="card border-1 mb-2">
      <div class="card-body pt-2 pb-2 px-3">
        <div class="row">
          <div class="col-9 small">
            <p class="mb-0">Tienes una deuda préstamo de:</p>
          </div>
          <div class="col-3 small text-right">
            <p class="text-primary mb-0"><strong>${{ data.payment.loan.toLocaleString('es-CL') }}</strong></p>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-5">
      <p>Total a pagar:
        <span class="text-primary">
            <strong>${{ data.payment.total.toLocaleString('es-CL') }}</strong>
          </span>
      </p>
    </div>

    <div class="text-left">
      <p><span class="text-primary"><strong>1.</strong></span> Elige tu forma de pagar</p>
    </div>

    <div>

      <div v-for="(prov) in data.providers" :key="prov.name"
           class="border-1 border-gray-color-3 rounded mb-3 hover-border-primary"
           v-bind:class="{'selected': (prov.name === provider.name)}">
        <label :for="prov.name" class="w-100 cursor-pointer px-3 pt-3 pb-2">
          <div class="row">
            <div class="align-self-center col-1">
              <input :id="prov.name" type="radio" name="provider" :value="prov.distributor"
                     @click="setProvider(prov.name, prov.distributor)" data-price="0" required="required"
                     class="form-check-input" :disabled="loading">
              <label :for="prov.name" class="form-check-label"></label>
            </div>
            <div class="col-7 pl-3 text-left">
              <img :src="prov.logo" height="19" :alt="prov.name">
              <p class="mb-0">{{ prov.description }}</p>
            </div>
          </div>
        </label>
      </div>
    </div>

    <div v-if="errors.provider" class="invalid-feedback">
      <span>Selecciona un medio de pago</span>
    </div>

    <span class="d-block mb-5"></span>

    <div class="text-left">
      <p><span class="text-primary"><strong>2.</strong></span> Correo para recibir boleta</p>
    </div>

    <div class="form-group">
      <input id="email" type="email" name="email" value="" maxlength="50" required="required"
             autocomplete="email" placeholder="correo@ejemplo.cl" class="form-control "
             v-bind:class="{ 'is-invalid': errors.email }" v-model="email" :disabled="loading">
      <div v-if="errors.email" class="invalid-feedback">
        Ingresa un correo válido
      </div>
    </div>

    <span class="d-block mb-5"></span>


    <div>
      <div v-if="loading" class="vld-icon">
        <svg viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" width="65" height="60" stroke="#ee0001">
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(1 1)" stroke-width="2">
              <circle stroke-opacity=".25" cx="18" cy="18" r="18"></circle>
              <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.8s"
                                  repeatCount="indefinite"></animateTransform>
              </path>
            </g>
          </g>
        </svg>
      </div>
      <button v-if="!loading" class="btn btn-block btn-primary mb-3" @click="submit" type="button">
        Ir a pagar
      </button>
    </div>


  </div>
</template>

<script>
import {paymentService} from "@/services/PaymentService";
export default {
  name: 'PaymentTariffForm',
  props: {
    data: Object,
    msisdn: String,
  },

  data() {
    return {
      errors: {
        provider: false,
        email: false
      },
      loading: false,
      email: '',
      provider: {
        name: '',
        distributor: ''
      },
    }
  },

  components: {
    'taiff-card': () => import('@/components/TariffCard.vue'),
  },

  methods: {
    async submit() {
      // console.log("submit:", {
      //   msisdn: this.msisdn,
      //   email: this.email,
      //   provider: this.provider.name,
      //   distributor: this.provider.distributor
      // });
      if (!this.validate()) {
        return false
      }

      this.loading = true;
      let redirect = await this.create();
      if(!redirect) {
        this.loading = false;
        return false
      }
      return this.to(redirect);
    },

    validate() {
      this.errors.email = false;
      this.errors.provider = !this.provider.name;
      this.errors.email = !this.email;
      if (!/.+@.+\..+/.test(this.email)) {
        this.errors.email = true;
      }
      return !this.errors.provider && !this.errors.email;
    },

    async create() {
      let response = await paymentService.create(this.msisdn, this.email, this.provider.name, this.provider.distributor, this.grecaptcha);
      // console.log(JSON.stringify(response))
      return response?.redirect || false;
    },

    setProvider(name, distributor) {
      this.provider.name = name;
      this.provider.distributor = distributor;
    },
    to(redirect) {
      if (redirect.method == "GET") {
        let url = "";
        Object.entries(redirect.fields).forEach(([key, value]) => {
          (url == "") && (url = "?" );
          url += `${key}=${value}`;
          (Object.keys(redirect.fields).length >= url.split("=").length) && (url += "&");
        });
        window.location.href = redirect.url + url;
        return true;
      }
      let form = document.createElement("form");
      form.id = 'POST';
      form.method = "POST";
      form.action = redirect.url;
     
      Object.entries(redirect.fields).forEach(([key, value]) => {
        let element = document.createElement("input");
        element.name = key;
        element.id = key;
        element.value = value;
        element.type = 'hidden';
        form.appendChild(element)
      });

      document.body.appendChild(form);
      form.submit();
      return true;
    }

  },

  computed: {},
}
</script>

<style scoped>
</style>

